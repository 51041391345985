import { render, staticRenderFns } from "./EditassociateData.vue?vue&type=template&id=1e8ff71c&scoped=true"
import script from "./EditassociateData.vue?vue&type=script&lang=js"
export * from "./EditassociateData.vue?vue&type=script&lang=js"
import style0 from "./EditassociateData.vue?vue&type=style&index=0&id=1e8ff71c&prod&lang=less&scoped=true"
import style1 from "./EditassociateData.vue?vue&type=style&index=1&id=1e8ff71c&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e8ff71c",
  null
  
)

export default component.exports